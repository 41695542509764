import { faDollarSign, faHome, faPlayCircle, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Tour from '../../Assets/About/sideseaction.jpg'
const CounterBox = ({ fontName, count, title }) => {
  return (
    <div className="d-flex justify-content-around align-items-center">
      <div className="icon">
        <FontAwesomeIcon icon={fontName} className='display-6' />
      </div>
      <div className="side-details">
        <h5 className='m-0 nunitoFont'>{count}</h5>
        <small className='m-0'>{title}</small>
      </div>
    </div>
  )
}
const Mission = () => {
  return (
    <Container className='mission'>
      <div className="container-inner">
        <h2 className="m-auto text-center text-warning nunitoFont my-5">Our Mission is to find you a Better and Affordable Home</h2>
        <Row style={{alignItems: 'center'}}>
          <Col lg={7} md={7} sm={12}>
            <div className="nunitoFont">
              <div className="side-heading">
                <p className='m-0'>
                PropertyRoll is founded by a group of dedicated property agents who have a common goal towards our clients - to help them achieve housing stability and financial literacy through real estate.
                </p>
              </div>
            </div>
            <div className='my-5'>
              The team commits to guide clients every step of the way to secure your dream home and/or investment asset

              <ul className='mt-3'>
                <li>Offer property reviews and investment insights </li>
                <li>Avoid mistakes and misconceptions  </li>
                <li>Minimising risks & choosing the right unit </li>
              </ul>
            </div>
            
          </Col>
          <Col lg={5} md={5} sm={12} className="right-side ">
            <div className="position-relative">
              <img src={Tour} alt="tour" className='img-fluid position-relatove' />
              {/* <FontAwesomeIcon icon={faPlayCircle} className='display-1 text-danger position-absolute' /> */}
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Mission;
