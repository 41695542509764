import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap'
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import MarkerImage from '../../Assets/location-pin.png'
import ActiveMarker from '../../Assets/location-pin-active.png'
import { useHistory } from 'react-router-dom';
import { mapstyles } from '../../Utils/mapstyles';
const LoadingContainer = () => {
    return (
        <div className='gmap-wrapper-parent'>
            &nbsp;
        </div>
    )
}
const PropertiesMap = (props) => {
    const history = useHistory()
    const [property, setProperty] = useState(false)
    const { locations, google } = props
    if (!locations) {
        return null
    }
    const initialMapstate = {
        currentLocation: {
            lat: 1.3554481,
            lng: 103.881026,
        }
    }

    const goToThisProperty = (id) => {
        history.push(`/property/${id}`)
    }
    return (
        <>
            <Row className={`mb-4 gmap-wrapper ${props.extraClasses}`}>
                <Col>
                    <Map
                        google={google}
                        className={'map'}
                        initialCenter={initialMapstate?.currentLocation}
                        zoom={10}
                        style={{ borderRadius: '25px' }}
                        zoomControl={true}
                        disableDefaultUI={true}
                        gestureHandling={"greedy"}
                        styles={mapstyles}
                    >
                        {React.Children.toArray(
                            locations?.map((location, id) => {
                                const { latitude, longitude, project_name: name, isActive, _id } = location
                                if (location.latitude && location.longitude) {
                                    return (
                                        <Marker
                                            className='marker'
                                            position={{ lat: latitude, lng: longitude }}
                                            icon={{
                                                url: isActive ? ActiveMarker : MarkerImage,
                                                scaledSize: new google.maps.Size(42, 42)
                                            }}
                                            onClick={() => goToThisProperty(_id)}
                                        />
                                    )

                                }
                                return null
                            })
                        )}


                    </Map>

                </Col>
            </Row>

        </>

    )
};
export default GoogleApiWrapper({
    apiKey: ('AIzaSyBv0qt6QTKqAGSynzrCQPy6gyLyEdE8HUY'),
    LoadingContainer: LoadingContainer

})(PropertiesMap)
