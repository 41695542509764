import React, { useEffect, useState } from 'react';
import { api } from '../Interceptor'
import PropertyImagesCarousal from '../Components/PropertyImagesCarousal'
import { Badge, Card, Col, Container, Row } from 'react-bootstrap'
import CustomLoader from '../Components/CustomLoader';
import useProperties from '../Utils/useProperties';
import moment from 'moment';
import ContactMap from '../Components/ContactMap';
import { facilities } from '../Utils/facility';
import PropertyModal from '../Components/Modal/PropertyModal'
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import PropertyImagesSwiper from '../Components/PropertyImageSwiper';

const Property = () => {
  const [loader, setLoader] = useState(false)
  const [property, setProperty] = useState({})
  const [location, setLocation] = useState({})
  const [floorPlans, setFloorPlans] = useState({})
  const [isMapAvailable, setMapAvailability] = useState(false)
  const { id } = useParams();

  const smTableKeys = [
    { name: "Min Price", key: "min_price", type: 'number' },
    { name: "Max Price", key: "max_price", type: "number" },
    { name: "No Of Bedrooms", key: "bedrooms", type: "string" },
    { name: "No Of Bathrooms", key: "bathrooms", type: "string" },
    { name: "Area", key: "land_size", type: "number" },
    { name: "No Of Units", key: "units", type: "string" }
  ]

  const getEstimatedTop = (date) => {
    const d = new Date(date)
    return moment(d).format('DD-MMM-YYYY')
  }

  const getProperty = async () => {
    setLoader(true)
    const { data: { property: prop } } = await api.method('get', `property/${id}`)
    await setProperty(prop)
    let isLatitudePresent = prop.latitude
    let isLongitudePresent = prop.longitude
    if (isLatitudePresent && isLongitudePresent) {
      setMapAvailability(true)
    }
    await setLoader(false)
    setLocation({
      latitude: prop.latitude,
      longitude: prop.longitude
    })
  }

  const closeFloorPlanModal = () => {
    setFloorPlans({
      isShown: false
    })
  }

  useEffect(() => {
    getProperty()
  }, []);

  const getPrices = () => {
    let min_prices = [0];
    let max_prices = [0];
    if (property.varients && property.varients.length > 0) {
      min_prices = property.varients.filter(x => x.min_price).map(x => x.min_price).sort();
      max_prices = property.varients.filter(x => x.max_price).map(x => x.max_price).sort();
    }

    return {
      lowest_price: Math.min(...min_prices),
      highest_price: Math.max(...max_prices),
    }
  }

  const formatNumber = (text, key, isPrice) => {
    const unit = ((key && key.key && key.key.includes('price')) || isPrice) ? "SGD" : "";
    if (!text) {
      return "";
    }
    return `${unit} ${new Intl.NumberFormat('en-SG').format(text)}`
  }

  if (loader) {
    return <CustomLoader />
  }
  if (property) {
    return <div className='visited-property'>
      <div className="prpnx-title-area">
      <Container>
        <h4>{property?.project_name}</h4>
        <p>{property?.address}</p>
        {property?.property_status ? <Badge bg="danger">{property?.property_status.toUpperCase()}</Badge> : null}
      </Container>
      </div>
      <PropertyImagesSwiper
        images={property?.property_images}
        name={property?.project_name}
      />
      <Container className='my-3 property-price-card'>
        <Row>
          <Col lg={9} md={8}>
          <Card className='m-2'>
          <Card.Body>
            <div className='d-flex justify-content-between '>
              <div className="price-range">
                <h3 className='h3'>Price range (indicative)</h3>
                <p>
                  {`${formatNumber(getPrices().lowest_price, null, true)} ~ ${formatNumber(getPrices().highest_price, null, true)}`}
                </p>
              </div>
              {property?.land_size && <div className="sale-range">
                <h3 className='h3'>Land Size</h3>
                <p>
                  {property.land_size ? `${new Intl.NumberFormat('en-SG').format(property.land_size)} .sqft` : ""}
                </p>
              </div>}

            </div>
            <div className=' gotomap'>
              See unit types and prices
            </div>

          </Card.Body>

        </Card>
        { property?.announcement || property?.summary && (
          <Card className='m-2'>
            <Card.Body>
              <div className='d-flex flex-column '>
                {property?.announcement && <div>
                  <h3 className='h3'>Announcement</h3>
                  <p>
                    {property?.announcement}
                  </p>
                </div>}
                {property?.summary && <details open={true} >
                  <summary className='h3'>Summary</summary>
                  <p>
                    {property?.summary}
                  </p>
                </details>}
              </div>
            </Card.Body>

          </Card>
        ) }
        
        <Card className='m-2'>
          <Card.Body>
          <Row className="">
            {
              property?.estimate_top && <div className="col-lg-6">
                <h4>
                  TOP date
                </h4>
                <p>
                  {getEstimatedTop(property?.estimate_top)}
                </p>
              </div>
            }
            {property?.units && <div className="col-lg-6">
              <h4>
                No. of units
              </h4>
              <p>
                {`${property && property?.units}`}
              </p>
            </div>}
            {property?.address && <div className="col-lg-6">
              <h4>
                Address
              </h4>
              <p>
                {`${property?.address} `}
                <br />
                {isMapAvailable ? <small className='text-primary'><a href='#contact-map'>View On Map</a></small> : null}
              </p>
            </div>}
            {property?.project_type && <div className="col-lg-6">
              <h4>
                Project type
              </h4>
              <p>
                {`${property?.project_type} `}
              </p>
            </div>}
            {property?.bedrooms && <div className="col-lg-6">
              <h4>
                Bedrooms
              </h4>
              <p>
                {`${property?.bedrooms.toString() ? property?.bedrooms.toString() : '---'} `}
              </p>
            </div>}
            {property?.tenure && <div className="col-lg-6">
              <h4>
                Tenure
              </h4>
              <p>
                {`${property?.tenure ? property?.tenure : '---'} `}
              </p>
            </div>}
            {property?.architect && <div className="col-lg-6">
              <h4>
                Architect
              </h4>
              <p>
                {`${property?.architect ? property?.architect : '---'} `}
              </p>
            </div>}
            {property?.bathrooms && <div className="col-lg-6">
              <h4>
                Bathroom
              </h4>
              <p>
                {`${property?.bathrooms ? property?.bathrooms : '---'} `}
              </p>
            </div>}

          </Row>
          </Card.Body>
        </Card>
        {property?.facilities?.length > 0 && <Card className='m-2'>
          <Card.Body>
            <h3>Facilities</h3>
              <Row className='my-3'>
                {property?.facilities ? property?.facilities?.map((facility, id) => {

                  return (
                    <Col lg={6}>
                      <h6 className='nunitoFont'>{id + 1} - {facility}</h6>
                    </Col>
                  )
                })
                  :
                  <h4>---</h4>
                }
              </Row>
          </Card.Body>
        </Card>}
        <Card className='m-2'>
          <Card.Body>
          <h3 className='nunitoFont p-3'>Variants</h3>
          <div class="table-responsive variant-table-lg">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Bedrooms</th>
                  <th scope="col">Bathrooms</th>
                  <th scope="col">Min Price</th>
                  <th scope="col">Max Price</th>
                  <th scope="col">Unit Size</th>
                  <th scope="col">Unit Type</th>
                  <th scope="col">Unit By Type</th>
                  <th scope="col">Floorplan</th>
                </tr>
              </thead>
              <tbody>
                {React.Children.toArray(
                  property?.varients?.map((data, id) => {
                    return (
                      <>
                        <tr>
                          <th scope="col">
                            {data.bedrooms ? data.bedrooms : "-"}
                          </th>
                          <th scope="col">
                            {data.bathrooms ? data.bathrooms : "-"}
                          </th>
                          <th>
                            {data.min_price ? formatNumber(data.min_price, null, true) : <a href="http://wa.me/6582425588" className='text-dark'>Call to enquire</a>}
                          </th>
                          <th>
                            {data.max_price ? formatNumber(data.max_price, null, true) : <a href="http://wa.me/6582425588" className='text-dark'>Call to enquire</a>}
                          </th>
                          <th>
                            {data.unit_size ? data.unit_size : "-"}
                          </th>
                          <th>
                            {data.unit_type ? data.unit_type : "-"}
                          </th>
                          <th>
                            {data.units_by_type ? data.units_by_type : "-"}

                          </th>
                          <th>
                            {!!data.floor_plan ?
                              <span className='text-link' onClick={() => {
                                setFloorPlans({
                                  isShown: true,
                                  floor_plans: data?.floor_plan
                                })
                              }}>{"See Floor Plan"}</span>
                              : "-"}
                          </th>
                        </tr>
                      </>
                    )
                  })
                )}

                {property?.varients?.length === 0 && (
                  <tr>
                    <td style={{textAlign: 'center'}} colSpan={100}>No varients found!</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div class="table-responsive variant-table-sm">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th></th>
                  {property?.varients?.map(({ unit_type }) => <th>{unit_type}</th>)}
                </tr>
              </thead>
              <tbody>
                {property?.varients?.length > 0 && smTableKeys.map((key) => {
                  return (
                    <tr>
                      <th className='sm-first-row'>{key.name}</th>
                      {property?.varients?.map((data) => {
                        return (
                          <th>
                            {/* {isNaN(data[`${key.key}`]) ? data[`${key.key}`] : formatNumber(data[`${key.key}`])} */}
                            {data[`${key.key}`] && isNaN(data[`${key.key}`]) ? data[`${key.key}`] : !isNaN(data[`${key.key}`]) ? formatNumber(data[`${key.key}`], key) : "-"}
                          </th>
                        )
                      })}
                    </tr>
                  )
                })}
                {property?.varients?.length === 0 && (
                    <tr>
                      <td style={{textAlign: 'center'}} colSpan={100}>No varients found!</td>
                    </tr>
                  )}
              </tbody>


            </table>
          </div>
          </Card.Body>
        </Card>
        {isMapAvailable && <Card className='contact-map m-2 py-4' id="contact-map">
          {isMapAvailable ? <ContactMap
            location={location}
          /> : null}
        </Card>}
          </Col>
          <Col lg={3} md={4}>
          <aside
            id="contactLead"
            class="mortar-wrapper rightRailScheduleTourContactSection"
          >
            <h2 class="scheduleTourContactTitle">Enquire on this Property</h2>

            <div class="ctaContainer">
              <a href='#section-contact'
                onClick={() => document.querySelector('.js-input-message').value = `Hey! Would like to find out more about ${property.project_name}. Please acknowledge. Thank you!`}
                class="requestTourBtn btn btn-md btn-secondary"
              >
                <FontAwesomeIcon icon={faEnvelope} className='text-primary ' /> Enquire
              </a>
              <a href={`http://wa.me/6586548658`} target="blank"
                class="sendMessageBtn btn btn-md btn-secondary"
              >
                <FontAwesomeIcon icon={faWhatsapp} className='text-primary ' /> Contact
              </a>

            </div>
          </aside>
          </Col>
        </Row>
      </Container>
      <PropertyModal handleClose={closeFloorPlanModal} show={floorPlans?.isShown} floor_plans={floorPlans?.floor_plans} />
    </div >;
  }
  return null
};

export default Property;
