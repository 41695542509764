import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import Placeholder from '../../Assets/placeholder.jpg';
import { Pagination, Navigation } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";

const PropertyImagesSwiper = ({ images, onImageClick, name, isDetail, address }) => {
  if (!images) {
    return null
  }

  if (images.length === 1) {
    return (
    <div className="propnx-carousel position-relative">
        <img
            className="d-block w-100"
            src={images[0]}
            alt="First slide"
            onError={(event) => {
                event.target.src = Placeholder
            }}
        />
    </div>
    )
}

  return <>

    <Swiper
      pagination={{
        dynamicBullets: true,
        clickable: true,
      }}
      navigation={true}
      modules={[Pagination, Navigation]}
      className='prpnx-swiper-detail'
      spaceBetween={5}
      slidesPerView={"auto"}
    >
      {
        React.Children.toArray(images.map((image, i) => {
            if (!image) return <></>;
            return (
              <SwiperSlide>
                <img src={image} onError={(e) => e.currentTarget.src = Placeholder} alt={`Property ${i+1}`} />
              </SwiperSlide>
            )
          })
        )
      }
    </Swiper>
  </>;
};

export default PropertyImagesSwiper;
