import React, { useState } from 'react';
import { faEnvelopeSquare, faPencilAlt, faPhoneAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Container, Form, FormControl, InputGroup, Row, Spinner } from 'react-bootstrap';
import { api } from '../../Interceptor'
import ToastMessage from '../ToastMessage/ToastMessage';
const SubmitButton = ({ loader }) => {
  return (
    <Button variant="primary" type='submit' className={`service ${loader ? 'is--loading' : '' }`}>
      <div class="loader-wrapper"><div class="loadersmall"></div></div>
      
      <div className='btn-content'>I'm interested</div>
    </Button>
  )
}
const ContactForm = () => {
  const checkboxOptions = [
    { label: "Make Appointment", value: "Make Appointment" },
    { label: "Request Floor Plan", value: "Request Floor Plan" },
    { label: "General Enquiry", value: "General Enquiry" },
  ]
  const [showMessage, setShowMessage] = useState(false);
  const [options, setOption] = useState([]);
  const [loader, setLoader] = useState(false)
  const [res, setRes] = useState({})
  const submitForm = async (event) => {
    event.preventDefault()
    setLoader(true)
    const formData = new FormData(event.target)
    let body = {}
    for (var pair of formData.entries()) {
      body = {
        ...body,
        [pair[0]]: pair[1]
      }
    }
    body.service_type = options.join(", ");
    const { data } = await api.method('post', '/query', body)
      .catch(({ response }) => {
        if (response) {
          setLoader(false)
          setRes({
            type: 'danger',
            result: "failed",
            message: 'There is error in contact query please try again !'
          })
          setShowMessage(true)

        }
      })
    if (+data.status === 200) {
      event.target.reset()
      setLoader(false)
      setRes({
        type: 'success',
        result: "Submitted",
        message: 'Thank you for contacting with us our team will get back to you !'
      })
      setShowMessage(true)

    }
  }
  const closeToaster = () => {
    setShowMessage(false)
  }
  return (
    <div>
      <Container>
        <Form onSubmit={submitForm} className="contact-form" id="section-contact">
          <Card className='contact-card'>
            <h2 className='text-capitalize nunitoFont'>Send Us A Message</h2>
            <Row className='my-3'>
              <Col lg={4} sm={12} md={4} className='my-2'>
                  <FormControl
                    placeholder="Enter Your Name"
                    aria-label="Enter Your Name"
                    aria-describedby="basic-addon2"
                    name='full_name'
                    required
                  />
              </Col>
              <Col lg={4} sm={12} md={4} className='my-2'>
                  <FormControl
                    placeholder="Enter Email Address"
                    aria-label="Enter Email Address"
                    aria-describedby="basic-addon2"
                    type='email'
                    name='email'
                    required
                  />
              </Col>
              <Col lg={4} sm={12} md={4} className='my-2'>
                  <FormControl
                    placeholder="Enter Phone Number"
                    aria-label="Enter Phone Number"
                    aria-describedby="basic-addon2"
                    type='number'
                    name='phone'
                    required
                  />
              </Col>
              <Col lg={12} sm={12} md={12} className='my-2'>
                  <div key={`inline-checkbox`}>
                    {checkboxOptions.map((x, index) => (
                      <Form.Check
                        inline
                        label={x.label}
                        value={x.value}
                        type={"checkbox"}
                        id={`inline-${index}`}
                        onChange={({currentTarget}) => {
                          if (currentTarget.checked) {
                            setOption([...options, x.value]);
                          } else {
                            options.splice(options.indexOf(x.value), 1);
                            setOption([...options]);
                          }
                        }}
                      />
                    ))}
                  </div>
              </Col>
              <Col lg={12} sm={12} md={12} className='my-2'>
                  <FormControl className='js-input-message' as="textarea" placeholder='Enter Message' required name='message' />
              </Col>
            </Row>
            <Row className='flex-column flex-md-row'>
              <Col>
              <strong>Disclaimer</strong> : By entering your information and clicking 'I'm interested" you agree to our Terms of Use and Privacy Policy and that you may be contacted by phone, text message and email about your inquiry.
              </Col>
              <Col className='d-flex justify-content-end col-auto'>
                <SubmitButton loader={loader} />
              </Col>
            </Row>
          </Card>
          <div className='d-flex justify-content-center align-items-center position-relative'>
            <ToastMessage showToaster={showMessage} closeToaster={closeToaster} {...res} />
          </div>

        </Form>
      </Container>
    </div>
  )

};

export default ContactForm;
