import { faFunnelDollar, faHouseUser, faPeopleCarry } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import GlobalCards from '../GlobalCards/Index';

const WhyChooseUs = () => {
  return (
    <Container className='mb-5'>
      <div className="text-center whycooseuse">
        <h2 className="mt-5 mb-2 nunitoFont">
          Why Choose Us
        </h2>
        <p>
          We provide full service at every step.
        </p>
      </div>
      <Row>
        <Col lg={4} md={4} sm={12}>
          <GlobalCards cardClassName="style--2" iconname={faPeopleCarry} title="Trusted By Thousands" />
        </Col>
        <Col lg={4} md={4} sm={12}>
          <GlobalCards cardClassName="style--2" iconname={faHouseUser} title="Wide Range Of Properties" />
        </Col>
        <Col lg={4} md={4} sm={12}>
          <GlobalCards cardClassName="style--2" iconname={faFunnelDollar} title="Financing Made Easy" />
        </Col>
      </Row>
    </Container>
  );
};

export default WhyChooseUs;
