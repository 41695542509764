import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const GoToTop = () => {
  const gototop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }
  return (
    <div className="gototop-icon" onClick={gototop}>
      <FontAwesomeIcon icon={faChevronUp} />
    </div>
  );
};

export default GoToTop;
