import React from 'react'
import { Route } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'
import GoToTop from '../Components/GoToTop/'
import ContactForm from '../Components/ContactForm';
const Layout = ({ component: Component, ...rest }) => {


    return (
        <Route {...rest} render={(props) => {
            return (<>
            <Header />
                <Component />
                <ContactForm />
                <Footer />
                <GoToTop />
            </>)
        }} />
    )
}

export default Layout
