import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import Placeholder from '../../Assets/placeholder.jpg';

const PropertyModal = ({ show, handleClose, floor_plans }) => {
  return <div>
    <Modal show={show} onHide={handleClose} centered size="lg" className='prop-model'>
      <Modal.Body>
        <h2 className='text-center'>Floor Plan</h2>
        <Row
        >
          <Col lg={12} className="my-2">
            <img
              className='w-100'
              src={floor_plans}
              onError={(event) => {
                event.target.src = Placeholder
              }}
            />
          </Col>

        </Row>
      </Modal.Body>

    </Modal>
  </div>;
};

export default PropertyModal;
