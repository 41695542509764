import { useEffect, useState } from 'react';
import { api } from '../Interceptor'
const useProperties = () => {
  const [properties, setPoperties] = useState({})
  const [filter, setFilter] = useState({})
  const getData = async () => {
    const { data: { properties: allProperties } } = await api.method('get', 'properties')
    
    const propertiesLocations = allProperties.map(({ latitude, longitude, _id, project_name }) => {
      return { _id, latitude, longitude, project_name }
    })

    const aggregatedData = { min_price: [], max_price: [], bedrooms: [], bathrooms: [] }
    allProperties.forEach(property => {
      aggregatedData.min_price = property.varients.filter(x => x.min_price).map(x => x.min_price).sort();
      aggregatedData.max_price = property.varients.filter(x => x.max_price).map(x => x.max_price).sort();
      aggregatedData.bedrooms = property.varients.filter(x => x.bedrooms).map(x => x.bedrooms).sort();
      aggregatedData.bathrooms = property.varients.filter(x => x.bathrooms).map(x => x.bathrooms).sort();

      property.lowest_price = Math.min(...aggregatedData.min_price) !== Infinity ? Math.min(...aggregatedData.min_price) : 0;
      property.highest_price = Math.max(...aggregatedData.max_price) !== Infinity ? Math.max(...aggregatedData.max_price) : 0;
      property.bedrooms_stringified = aggregatedData.bedrooms.join();
      property.bathrooms_stringified = aggregatedData.bathrooms.join();

      property.aggregatedData = {...aggregatedData};
    });

    setPoperties({
      location: propertiesLocations,
      allProperties: allProperties,
    })
  }
  useEffect(() => {
    getData()
  }, []);
  useEffect(() => {
    if (filter.length === 0) {
      return;
    }

    let _filterData = properties?.allProperties

    if (filter.search) {
      _filterData = _filterData.filter((prop) => {
        if (prop.project_name.toLowerCase().includes(filter.search.toLowerCase()) || (prop.address && prop.address.toLowerCase().includes(filter.search.toLowerCase())) || (prop.district && prop.district.toLowerCase().includes(filter.search.toLowerCase())) || (prop.region && prop.region.toLowerCase().includes(filter.search.toLowerCase()))) {
          return prop
        }
      })
    }
    if (filter.min_price) {
      _filterData = _filterData.filter((prop) => {
        if (filter?.min_price >= +(prop.lowest_price)) {
          return prop
        }
      })
    }
    if (filter.max_price) {
      _filterData = _filterData.filter((prop) => {
        if (filter?.max_price <= +(prop.highest_price)) {
          return prop
        }
      })
    }
    if (filter.tenure) {
      _filterData = _filterData.filter((prop) => {
        if (prop.tenure === filter?.tenure) {
          return prop
        }
      })
    }
    if (filter.project_type) {
      _filterData = _filterData.filter((prop) => {
        if (prop.project_type === filter?.project_type) {
          return prop
        }
      })
    }
    if (filter.bedrooms) {
      _filterData = _filterData.filter((prop) => {
        if (prop.bedrooms_stringified.includes(filter.bedrooms)) {
          return prop;
        }

        if (filter.bedrooms === '5+') {
          if (prop.aggregatedData.bedrooms.filter((val) => val > 5)) {
            return prop;
          }
        }
      })
    }
    if (filter.bathrooms) {
      _filterData = _filterData.filter((prop) => {
        if (prop.bathrooms_stringified.includes(filter.bathrooms)) {
          return prop;
        }

        if (filter.bathrooms === '5+') {
          if (prop.aggregatedData.bathrooms.filter((val) => val > 5)) {
            return prop;
          }
        }
      })
    }
    const latLong = _filterData?.map(({ latitude, longitude, _id, project_name }) => {
      return {
        _id,
        latitude,
        longitude,
        project_name
      }
    })
    setPoperties({
      ...properties,
      location: latLong,
      filteredData: _filterData
    })
  }, [filter]);


  return [properties, setPoperties, filter, setFilter];
};

export default useProperties;
