import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CustomLoader from '../CustomLoader';
import Pin from '../../Assets/location-pin.png'
import {mapstyles} from '../../Utils/mapstyles'
const ContactMap = (props) => {
  const { location, google } = props
  const [initialMapstate, setInitialMapstate] = useState({lat: 1.3554481, lng: 103.881026});
  
  useEffect(() => {
    if (location.latitude) {
      setInitialMapstate({lat: location.latitude, lng: location.longitude,})
    }
  }, [location]);

  if (!location) {
    return <CustomLoader />
  }
  return (
    <>
      <Container className='my-4'>
        <Row className='mb-4 gmap-wrapper'>
          <Col>
            <Map
              google={google}
              className={'map'}
              zoom={17}
              // initialCenter={{lat: 1.3554481, lng: 103.881026}}
              center={initialMapstate}
              zoomControl={true}
              disableDefaultUI={true}
              gestureHandling={"greedy"}
              styles={mapstyles}
            >
              <Marker
                position={{ lat: location.latitude, lng: location.longitude }}
                icon={{
                  url: Pin,
                  scaledSize: new google.maps.Size(42, 42)
                }}
              />
            </Map>

          </Col>
        </Row>

      </Container>
    </>
  )

};

export default GoogleApiWrapper({
  apiKey: ('AIzaSyBv0qt6QTKqAGSynzrCQPy6gyLyEdE8HUY')
})(ContactMap);
