import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card } from 'react-bootstrap';

const GlobalCards = ({ iconname, title, description, description2, link1, link2, cardClassName }) => {
  return (
    <Card className={`text-center why-choose-us-card ${cardClassName}`}>
      <Card.Body>
        <div className="circle-container">
          <FontAwesomeIcon icon={iconname} className='display-1 my-5' color='#e85a40' />
        </div>
        <Card.Title>{title}</Card.Title>
        <Card.Text style={{ fontSize: '15px' }}>
          <a className='text-decoration-none text-dark' href={link1} target="blank">{description ? description : ''}</a>

          <br />
          <a className='text-decoration-none text-dark' href={link2} target="blank">{` ${description2 ? description2 : ''}`}</a>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default GlobalCards;
