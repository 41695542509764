import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import routes from '../routes';
import Logo from '../Assets/logo.png'

const Footer = () => {
  return (
    <>
      <footer className="prpnx-footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5">
              <div className="footer_about_widget">
              <img src={Logo} class="logo" alt="logo" />
                <p className=''>
                  <div className="row">
                    <div className="col-10">
                      We’re reimagining how you buy, sell and rent. It’s now easier to get into a place you love. So let’s do this, together.
                    </div>
                  </div>
                </p>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="footer_qlink_widget">
                <h4>Quick Links</h4>
                <ul className="prpnx-sec-linklist">
                  {
                    React.Children.toArray(
                      routes.map(({ name, path, notInHeader }) => {
                        if (notInHeader) {
                          return;
                        }
                        return (
                          <li>
                            <Link to={path}>{name}</Link>
                          </li>
                        )
                      })
                    )
                  }
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="footer_contact_widget">
                <h4>Contact Us</h4>
                <ul className="prpnx-sec-linklist">
                  <li>
                    <FontAwesomeIcon icon={faEnvelopeSquare} color="#fff" className='mx-2' />
                    <a href="mailto:enquiry@propertyroll.sg">
                      enquiry@propertyroll.sg
                    </a>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faWhatsapp} color="#fff" className='mx-2' />
                    <a target="blank" href="http://wa.me/6586548658">Contact Priscillia</a>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faWhatsapp} color="#fff" className='mx-2' />
                    <a target="blank" href="http://wa.me/6582425588">Contact Calvyn</a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div className="prpnx-footer-bottom">
            <Row>
              <Col sm={6}>
                <span>&copy; PropertyRoll SG {new Date().getFullYear()}. All Rights Reserved.</span>
              </Col>
              <Col sm={6}>
                <Link to='/privacy-policy' className='prpnx-sec-link'>Privacy Policy</Link>
              </Col>
            </Row>

          </div>
        </div>
      </footer>
    </>
  )
};
export default Footer;
