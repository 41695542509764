import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelopeSquare, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import GlobalCards from '../GlobalCards/Index';

const ContactInfo = () => {
  return (
    <Container className='my-5 py-5'>
      <Row>
        <Col lg={4} md={4} sm={12}>
          <GlobalCards iconname={faEnvelopeSquare} title="Email Address" description={"enquiry@propertyroll.sg"} link1={"mailto:enquiry@propertyroll.sg"} />
        </Col>
        <Col lg={4} md={4} sm={12}>
          <GlobalCards iconname={faWhatsapp} title="Phone Number" description={"Contact Priscillia"} link1={"http://wa.me/6586548658"} description2={"Contact Calvyn"} link2={"http://wa.me/6582425588"} />
        </Col>
        <Col lg={4} md={4} sm={12}>
          <GlobalCards iconname={faMapMarkerAlt} title="Address" description={"Singapore"} />
        </Col>
      </Row>
    </Container>
  )
};

export default ContactInfo;
